<template>
  <div v-if="!staticError" :key="$route.path">
    <component
      :is="getComponent"
      v-if="getComponent"
      :cms-page="cmsPage"
      :page="staticPage"
    />
  </div>
</template>
<script>
import { ref } from '@nuxtjs/composition-api'
import { useCms } from '~/composables'
import CategoryView from '~/components/views/CategoryView'

const pagesMap = {
  'frontend.navigation.page': CategoryView,
  'frontend.detail.page': () => import('@/components/views/ProductView'),
  'frontend.landing.page': () =>
    import(/* webpackPrefetch: -1 */ '@/components/views/LandingView'),
  'frontend.bs_blog.page': () =>
    import(/* webpackPrefetch: -1 */ '@/components/views/BlogView'),
  'error.page': () => import('@/components/views/ErrorView'),
}

export function getComponentBy(resourceType) {
  if (!resourceType || !pagesMap[resourceType]) return
  return pagesMap[resourceType]
}

export default {
  name: 'DynamicRoute',
  components: {},
  setup(_, { root }) {
    const { page, error } = useCms(root)

    const cmsPage = ref(page.value?.cmsPage)
    const staticPage = ref(page.value)
    const staticError = ref(error.value)

    if (staticError.value) {
      root.error({
        statusCode: staticError.value.statusCode,
        message: JSON.stringify(staticError.value.messages),
      })
    }

    return {
      cmsPage,
      staticPage,
      staticError,
    }
  },
  computed: {
    getComponent() {
      if (this.staticError) {
        return null
      }

      return this.staticPage && getComponentBy(this.staticPage.resourceType)
    },
  },
}
</script>
