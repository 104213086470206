<template>
  <cms-page :content="cmsPage"></cms-page>
</template>

<script>
import { computed, defineComponent, useMeta } from '@nuxtjs/composition-api'
import { onMounted } from '@vue/composition-api'
import {
  getApplicationContext,
  INTERCEPTOR_KEYS,
  useIntercept,
  useListing,
  useListingFilter,
} from '~/composables'
import CmsPage from '~/components/cms/CmsPage'
import { buildUrl, getSmallestMediaThumbnailUrl } from '~/helpers'
import { ATTRIBUTE_CATEGORY_META_ROBOTS } from '~/services/cfAttributes'
import { simpleHtmlEntityDecoder } from '~/helpers/simpleHtmlEntityDecoder'

export default defineComponent({
  name: 'CategoryView',
  components: { CmsPage },
  props: {
    cmsPage: {
      type: Object,
      default: () => ({}),
    },
    page: {
      type: Object,
      default: () => ({}),
    },
  },
  setup({ page, cmsPage }, { root }) {
    const { route, i18n, config } = getApplicationContext(root)
    const { getCurrentPage } = useListing(root, 'categoryListing')

    const { hasActiveFilter } = useListingFilter(root, 'categoryListing')

    const { broadcast } = useIntercept(root)

    onMounted(() => {
      broadcast(INTERCEPTOR_KEYS.PAGE_VIEW_PLP, {
        category: page.category,
        resourceIdentifier: page.resourceIdentifier,
      })
    })

    const shouldUseCanonical = computed(() => {
      return getCurrentPage.value <= 1 && !hasActiveFilter.value
    })

    const isHomepage = computed(() => {
      return route.path === '/'
    })

    useMeta(() => {
      const meta = {
        title:
          page.category.translated.metaTitle || page.category.translated.name,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: simpleHtmlEntityDecoder(
              page.category.translated.metaDescription || ''
            ),
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content: simpleHtmlEntityDecoder(
              page.category.translated.metaDescription ||
                page.category.translated.name
            ),
          },
          {
            hid: 'og:title',
            name: 'og:title',
            content:
              page.category.translated.metaTitle ||
              page.category.translated.name,
          },
          {
            hid: 'og:type',
            name: 'og:type',
            property: 'og:type',
            content:
              cmsPage?.type === 'product_list' ? 'product.group' : 'website',
          },
        ],
        link: [],
        script: [],
      }

      const categoryRobots =
        page.category.translated?.customFields?.[ATTRIBUTE_CATEGORY_META_ROBOTS]

      const hasNoindex = !!categoryRobots?.includes('noindex')

      if (categoryRobots && categoryRobots !== '0') {
        meta.meta.push({
          name: 'robots',
          content: categoryRobots.trim(),
        })
      }

      if (!hasNoindex) {
        if (!shouldUseCanonical.value) {
          meta.meta.push({
            name: 'robots',
            content: 'noindex,follow',
          })
        } else {
          meta.link.push({
            rel: 'canonical',
            href: buildUrl(root.$config.appUrl, page.canonicalPathInfo || '/'),
          })
        }
      }

      if (page.category.media) {
        meta.meta.push({
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: getSmallestMediaThumbnailUrl(page.category.media),
        })
      }

      if (isHomepage.value) {
        meta.script.push({
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: buildUrl(root.$config.appUrl, '/'),
            potentialAction: [
              {
                '@type': 'SearchAction',
                target: {
                  '@type': 'EntryPoint',
                  urlTemplate:
                    buildUrl(root.$config.appUrl, '/search') +
                    '?query={search_term_string}',
                },
                'query-input': 'required name=search_term_string',
              },
            ],
          },
          body: true,
        })

        if (i18n.locale === 'de-DE') {
          meta.script.push({
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'Organization',
              name: config.isMaBuBuild ? 'MaBu-Kids.de' : 'Baby-Sweets.de',
              url: buildUrl(root.$config.appUrl, '/'),
              logo: buildUrl(root.$config.appUrl, '/') + 'logo.svg',
              sameAs: config.isMaBuBuild
                ? [
                    'https://www.facebook.com/mabukidsde/',
                    'https://www.instagram.com/mabukids_de/',
                  ]
                : [
                    'https://www.facebook.com/babysweets2/',
                    'https://www.instagram.com/babysweets_de/',
                    'https://www.pinterest.de/baby_sweets/',
                    'https://www.tiktok.com/@babysweets_de',
                  ],
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  telephone: '+49-34638-17999-0',
                  email: config.isMaBuBuild
                    ? 'support@mabu-kids.de'
                    : 'support@baby-sweets.de',
                  contactType: 'customer service',
                  availableLanguage: 'German',
                },
              ],
            },
            body: true,
          })
        }
      }

      return meta
    })
  },
  head: {},
})
</script>
