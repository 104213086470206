const ENTITIES = [
  {
    regex: /&#x2764;/gi,
    replace: '❤',
  },
  {
    regex: /&#x2713;/gi,
    replace: '✓',
  },
  {
    regex: /&#x27A4;/gi,
    replace: '➤',
  },
  {
    regex: /&#x25BA;/gi,
    replace: '►',
  },
  {
    regex: /&#10003;/gi,
    replace: '✓',
  },
  {
    regex: /&#9734;/gi,
    replace: '☆',
  },
]

export function simpleHtmlEntityDecoder(text?: string): string | undefined {
  if (!text) {
    return text
  }

  for (const { regex, replace } of ENTITIES) {
    text = text.replace(regex, replace)
  }

  return text
}
